import React from "react";
import Sidenav from "./Sidenav";

const Dashboard2 = () => {
  return (
    <div>
      <Sidenav />
    </div>
  );
};

export default Dashboard2;
